.container {
	padding-bottom: 48px;
}

.vendor {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	width: 250px;
	height: 400px;
	background-color: #1f1f1f;
	@media screen and (max-width: 768px) {
		margin-right: 12px;
		&:last-of-type {
			margin-right: 0px;
		}
	}
}

.vendor:hover .image {
	// transform: scale(1.02);
	filter: brightness(1.09);
}

.image {
	z-index: 0;
	object-fit: cover;
	transition: 0.4s;
}

.gradient {
	position: absolute;
	left: 0;
	bottom: 75px;
	width: 100%;
	height: 175px;
	background: linear-gradient(0deg, #1f1f1f 0%, rgba(38, 38, 38, 0) 38.02%);
}

.info {
	position: relative;
	background-color: #1f1f1f;
	padding: 12px;
	height: 75px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	vertical-align: bottom;
}

.logoContainer {
	position: absolute;
	top: 8px;
	left: 8px;
	background-color: white;
	border-radius: 4px;
	padding: 4px;
	width: 110px;
	height: 42px;
	display: flex;
	justify-content: center;
}

.logo {
	width: 100%;
	height: 100%;
	max-width: 90px;
	max-height: 32px;
	object-fit: contain;
}

.title {
	margin-bottom: 32px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;