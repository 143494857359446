.container {
	border-radius: 4px;
	overflow: hidden;
	transition: opacity 0.2s;
	&:hover {
		opacity: 0.97;
	}
}

.imageContainer {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
}

.image {
	width: 100%;
	min-width: 280px;
	height: 200px;
	max-width: 100%;
	object-fit: cover;
}

.smallImage {
	width: 110px;
	height: 82px;
	object-fit: cover;
}

.categories {
	position: absolute;
	bottom: 8px;
	left: 8px;
	right: 8px;
}

.title {
	margin: 8px 0px 4px;
}

.infos {
	max-width: 280px;
}

.fullWidthContainer {
	position: relative;
}

.fullWidthImage {
	width: 1168px;
	max-width: 100%;
	height: 300px;
	object-fit: cover;
}

.fullWidthContent {
	position: absolute;
	bottom: 24px;
	left: 32px;
	right: 32px;
}

.gradient {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 70%;
	background: linear-gradient(rgba(38, 38, 38, 0), rgba(38, 38, 38, 1));
}

.largePreviewContainer {
	width: 1168px;
	max-width: 80vw;
	position: relative;
}

.largestImage {
	width: 100%;
	height: 460px;
	object-fit: cover;
}

.horizontalGradient {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(38, 38, 38, 1) 30%, rgba(38, 38, 38, 0) 100%);
}

.verticalGradient {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(38, 38, 38, 0) 0%, rgba(38, 38, 38, 1) 60%);
}

.largeContent {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 32px;
	width: 70%;
	max-width: 540px;
	@media screen and (max-width: 575px) {
		width: 100%;
		max-width: 100%;
		padding: 24px;
	}
}

.excerpt {
	margin: 14px 0px 12px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;