.image {
	display: block;
	width: 150px;
	height: 150px;
	margin-right: 50px;
	border-radius: 4px;
	overflow: hidden;
}

.bannerRow {
	height: auto;
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 64px;
	justify-content: space-between;
	@media only screen and (min-width: 760px) {
		flex-direction: row;
	}
}

.sponsorText {
	width: fit-content;
	text-overflow: ellipsis;
	white-space: nowrap;
	justify-self: flex-start;
	align-self: flex-start;
	padding-bottom: 24px;
	@media only screen and (min-width: 760px) {
		padding-bottom: 0px;
		align-self: center;
	}
}

.sponsorLogos {
	display: flex;
	overflow: hidden;
	width: 100%;
	transform: translate3d(0, 0, 0);
	@media only screen and (min-width: 760px) {
		width: auto;
		margin-left: 50px;
	}
}

.carousel {
	align-items: center;
	justify-content: space-between;
	display: flex;
	position: relative;
	animation: slideshow 35s linear infinite;
}

.carousel:hover {
	animation-play-state: paused;
}

@keyframes slideshow {
	100% {
		transform: translateX(-66.6666%);
	}
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;