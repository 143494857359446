.container {
	padding: 64px 12px;
	width: max-content;
	background-color: #262626;
	margin-left: calc((100vw - 1068px) * -0.5 + 24px);
}

@media screen and (max-width: 1168px) {
	.container {
		margin-left: -24px;
	}
}

.slider {
	display: flex;
	flex-direction: row;
	animation-duration: 120s;
	animation-iteration-count: infinite;
	animation-timing-function: cubic-bezier(0.2, 0, 0.8, 1);
	animation-name: slider;
}

@keyframes slider {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(-100%) translateX(100vw) translateX(-24px);
	}
	100% {
		transform: translateX(0px);
	}
}

.slider:hover {
	animation-play-state: paused;
}

.item {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	margin-right: 16px;
}

.image {
	height: 340px;
	width: 260px;
	object-fit: cover;
	transition: filter 0.4s;
}

.item:hover .image {
	filter: brightness(0.9);
}

.info {
	background-color: #262626;
	padding: 12px;
	position: absolute;
	bottom: -100px;
	left: 0;
	right: 0;
	transition: bottom 0.6s;
}

.item:hover .info {
	bottom: 0;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;