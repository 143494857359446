.wrapper {
	position: relative;
	overflow: hidden;
}

.container {
	height: 100%;
	min-height: 368px;
	padding: 24px 0px;
	overflow: hidden;
	@media screen and (max-width: 575px) {
		min-height: auto;
	}
}

.small {
	min-height: 240px;
	@media screen and (max-width: 575px) {
		min-height: auto;
	}
}

.imageContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 370px;
	@media screen and (max-width: 575px) {
		position: inherit;
		min-height: unset;
	}
}

.image {
	position: absolute;
	left: 17%;
	top: 0;
	width: 990px;
	max-width: 100%;
	height: 100%;
	object-fit: cover;
	@media screen and (max-width: 575px) {
		position: relative;
		max-width: 100vw;
		height: 240px;
		margin: 0px -24px;
		left: 0;
	}
}

.imageSmall {
	@media screen and (max-width: 575px) {
		height: 120px;
	}
}

.overlayImage {
	@media screen and (max-width: 1067px) {
		display: none;
	}
}

.fades {
	position: absolute;
	left: 0;
	right: 0;
	width: 100vw;
	height: 100%;
}

.light {
	background: linear-gradient(
		90deg,
		#f5f5f5 30%,
		rgba(245, 245, 245, 0) 69%,
		rgba(245, 245, 245, 0) 74%,
		#f5f5f5 100%
	);
}

.dark {
	background: linear-gradient(
		90deg,
		#262626 30%,
		rgba(38, 38, 38, 0) 69%,
		rgba(38, 38, 38, 0) 74%,
		#262626 100%
	);
}

@media screen and (min-width: 1168px) {
	.fades {
		left: calc(-0.5 * (100vw - 1168px));
		right: calc(-0.5 * (100vw - 1168px));
	}
	.light {
		background: linear-gradient(
			87deg,
			#f5f5f5 calc((0.5 * (100vw - 1168px)) + 360px),
			rgba(245, 245, 245, 0) calc(0.5 * (100vw - 1168px) + 700px),
			rgba(245, 245, 245, 0) calc(0.5 * (100vw - 1168px) + 840px),
			#f5f5f5 calc((0.5 * (100vw - 1168px)) + 1168px)
		);
	}
	.dark {
		background: linear-gradient(
			87deg,
			#262626 calc((0.5 * (100vw - 1168px)) + 320px),
			rgba(38, 38, 38, 0) calc(0.5 * (100vw - 1168px) + 700px),
			rgba(38, 38, 38, 0) calc(0.5 * (100vw - 1168px) + 840px),
			#262626 calc((0.5 * (100vw - 1168px)) + 1168px)
		);
	}
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.title {
	margin: 4px 0px 12px;
	color: white;
	.light {
		color: #010101;
	}
}

.link {
	margin-top: 12px;
}

.themenwelten {
	margin-bottom: 16px;
	flex-wrap: nowrap;
}

.icon {
	width: 52px;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;