.wrapper {
	margin-left: calc(-0.5 * (100vw - 1168px));
	@media screen and (max-width: 1168px) {
		margin: 0px -24px;
	}
	width: 100vw;
	overflow-x: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}

.wrapper::-webkit-scrollbar {
	/* WebKit */
	width: 0;
	height: 0;
}

.row {
	flex-wrap: nowrap;
	width: max-content;
	margin-left: calc(0.5 * (100vw - 1168px));
	padding-right: calc(0.5 * (100vw - 1168px) - 32px);
	@media screen and (max-width: 1168px) {
		margin-left: 0px;
		padding: 0px 0px 0px 24px;
	}
	@media screen and (max-width: 768px) {
		padding: 0px 16px 0px 24px;
	}
}

.row > div {
	margin-right: 32px;
	@media screen and (max-width: 768px) {
		margin-right: 12px;
	}
}

.smallGaps {
	padding-right: calc(0.5 * (100vw - 1168px) - 16px) !important;
}

.smallGaps > div {
	margin-right: 16px;
	@media screen and (max-width: 768px) {
		margin-right: 8px;
	}
}

.arrowBtn {
	color: @grey-8;
	font-size: 24px;
	margin-top: 12px;
	transition: color 0.3s;
	&:hover {
		color: @grey-9;
	}
	&.light {
		color: @grey-4;
	}
}

.arrowBtnDisabled {
	color: @grey-6;
	&:hover {
		color: @grey-6;
	}
	&.light {
		color: @grey-8;
	}
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;