.col:hover .image,
.colSM:hover image {
	transform: scale(1.04);
	opacity: 0.87;
}

.colSM {
	width: 320px;
	height: 320px;
	max-width: calc(100vw - 64px);
	max-height: calc(100vw - 64px);
	position: relative;
	margin-bottom: 16px;
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.94) 0%, rgba(0, 0, 0, 0.88) 71.58%);
	padding: 24px;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	overflow: hidden;
}

.imageContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 4px;
	overflow: hidden;
}

.image {
	width: 100%;
	height: 100%;
	transition: 0.4s;
}

.text {
	position: absolute;
	bottom: 32px;
	left: 32px;
	margin-right: 16px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;