.hero {
	position: relative;
	height: 760px;
	max-height: calc(150vw + 120px);
	@media screen and (max-width: 575px) {
		height: 570px;
		max-height: 570px;
	}
}

.image {
	position: absolute;
	top: 0;
	left: 0;
	margin-left: -50vw;
	left: 50%;
	width: 100vw;
	height: 100%;
	max-height: 120vw;
	@media screen and (max-width: 574px) {
		max-height: none;
	}
	object-fit: cover;
	object-position: 50% 50%;
}

.content {
	margin: 260px 0px 24px;
	@media screen and (max-width: 575px) {
		margin: 24px 0px;
	}
	text-align: center;
}

.title {
	font-size: 52px;
	font-weight: bold;
	color: white;
	@media screen and (max-width: 575px) {
		font-size: 38px;
	}
}

.handwritten {
	margin: -40px 0px -26px;
	@media screen and (max-width: 575px) {
		margin: -32px 0px -20px;
	}
}

.handwrittenText {
	font-family: zooja-pro, sans-serif;
	color: #0ad142;
	font-size: 86px;
	font-weight: 300;
	@media screen and (max-width: 575px) {
		font-size: 72px;
	}
}

.button {
	margin-top: 16px;
}

.gradient {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-left: -50vw;
	left: 50%;
	width: 100vw;
	height: 200px;
	background: linear-gradient(to top, rgba(16, 16, 16, 1), rgba(16, 16, 16, 0));
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;